export default function headerFixed() {
   const header = document.querySelector(".main-header");
   const iconMenu = document.querySelector(".icon-menu");

   if (header) {
      window.addEventListener("scroll", function () {
         if (window.pageYOffset > 150) {
            header.classList.add("fixed");
            iconMenu.classList.add("fixed");
         } else {
            header.classList.remove("fixed");
            iconMenu.classList.remove("fixed");
         }
      });
   }
}
