import { bodyLockStatus, bodyUnlock, bodyLockToggle } from "./functions";

export default function menu() {
   //=================
   //Menu
   let iconMenu = document.querySelector(".icon-menu");
   if (iconMenu != null) {
      let delay = 500;
      let menuBody = document.querySelector(".menu__body");

      iconMenu.addEventListener("click", function (e) {
         e.preventDefault();
         if (bodyLockStatus) {
            iconMenu.classList.toggle("_active");
            menuBody.classList.toggle("_active");
            bodyLockToggle();
         }
      });
   }
   function menu_close() {
      let iconMenu = document.querySelector(".icon-menu");
      let menuBody = document.querySelector(".menu__body");
      iconMenu.classList.remove("_active");
      menuBody.classList.remove("_active");
   }

   function addLoaded() {
      if (document.querySelector(".wrapper")) {
         setTimeout(function () {
            document.querySelector(".wrapper").classList.add("_loaded");
         }, 0);
      }
   }
   addLoaded();

   const menuLinks = document.querySelectorAll(".menu__link");

   menuLinks.forEach(function (link) {
      link.addEventListener("click", function () {
         bodyUnlock(0);
         menu_close();
      });
   });
}
