import { useState, useRef } from "react";
import { useOnClickOutside } from "../hooks/clickOutside";

function Input({ label, type = "text", tabIndex = "-1", fn, darkMode }) {
   const [inputValue, setInputValue] = useState("");
   const ref = useRef();

   function handleKeyDown(e) {
      if (e.code === "Enter" || e.code === "Tab") {
         if (inputValue.trim()) {
            let value = inputValue.trim();
            fn(value);
            // setInputValue("");
         }
      }
   }

   useOnClickOutside(ref, () => {
      if (inputValue.trim()) {
         let value = inputValue.trim();
         fn(value);
         setInputValue("");
      }
   });

   return (
      <div className={`c-input ? ${darkMode ? "c-input--dark" : ""}`} ref={ref}>
         <div className="c-input__label">{label}</div>
         <div className="c-input__input">
            <input
               value={inputValue}
               onChange={(e) => setInputValue(e.target.value)}
               tabindex={tabIndex}
               type={type}
               onKeyDown={handleKeyDown}
            />
         </div>
      </div>
   );
}

export default Input;
