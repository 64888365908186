function Loading() {
   return (
      <div
         class="tvdd"
         role="img"
         aria-label="Three intersecting rings of twelve pulsing dots that never collide"
      >
         <div class="tvdd__ring">
            <div class="tvdd__ring-dots">
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
            </div>
         </div>
         <div class="tvdd__ring">
            <div class="tvdd__ring-dots">
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
            </div>
         </div>
         <div class="tvdd__ring">
            <div class="tvdd__ring-dots">
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
               <div class="tvdd__ring-dot"></div>
            </div>
         </div>
      </div>
   );
}

export default Loading;
