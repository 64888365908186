import React, { useContext, useReducer, useEffect, useRef } from "react";
import { axiosInstance } from "../utils/axiosInstances";
import { initialState } from "./initialState";
import {
   GET_ADDS_BEGIN,
   GET_ADDS_ERROR,
   GET_ADDS_SUCCESS,
   GET_CITIES_SUCCESS,
   GET_PROVINCES_SUCCESS,
   GET_TYPOLOGY_SUCCESS,
   GET_ZONES_SUCCESS,
   RESET_FILTERS,
   SET_CITY,
   SET_CONTRACT,
   SET_MQ_MAX,
   SET_MQ_MIN,
   SET_ORDER,
   SET_PAGE,
   SET_PRICE_MAX,
   SET_PRICE_MIN,
   SET_RIF,
   SET_ROOMS,
   SET_TYPOLOGY,
   SET_ZONE,
} from "./siteActions";
import reducer from "./siteReducer";

const SiteContext = React.createContext();

export const SiteContextProvider = ({ children }) => {
   const isMounted = useRef(false);
   const isMounted2 = useRef(false);
   const [state, dispatch] = useReducer(reducer, initialState);

   const resetFilters = () => {
      dispatch({ type: RESET_FILTERS });
   };

   const setCity = (value) => {
      dispatch({ type: SET_CITY, payload: value });
   };

   const setZone = (value) => {
      dispatch({ type: SET_ZONE, payload: value });
   };

   const setRooms = (value) => {
      dispatch({ type: SET_ROOMS, payload: value });
   };

   const setContract = (value) => {
      dispatch({ type: SET_CONTRACT, payload: value });
   };
   const setTypology = (value) => {
      dispatch({ type: SET_TYPOLOGY, payload: value });
   };

   const setMqMin = (value) => {
      dispatch({ type: SET_MQ_MIN, payload: value });
   };

   const setMqMax = (value) => {
      dispatch({ type: SET_MQ_MAX, payload: value });
   };

   const setPriceMin = (value) => {
      dispatch({ type: SET_PRICE_MIN, payload: value });
   };

   const setPriceMax = (value) => {
      dispatch({ type: SET_PRICE_MAX, payload: value });
   };

   const setRif = (value) => {
      dispatch({ type: SET_RIF, payload: value });
   };

   const setOrder = (value) => {
      dispatch({ type: SET_ORDER, payload: value });
   };

   const setPage = (value) => {
      dispatch({ type: SET_PAGE, payload: value });
   };

   const fetchTypology = async (residenziale = 1) => {
      try {
         const response = await axiosInstance.post(
            `/tipologie?residenziale=${residenziale}`
         );
         let typologiesData = response.data.data.tipologie_list;
         typologiesData = typologiesData.map((item) => ({
            ...item,
            id: item.Id_tipologia,
            label: item.tipologie,
         }));
         typologiesData.unshift({
            Id_tipologia: 0,
            tipologie: "Tutte",
            id: 0,
            label: "Tutte",
         });

         dispatch({ type: GET_TYPOLOGY_SUCCESS, payload: typologiesData });
      } catch (error) {
         console.log(error, "error fetch typology");
      }
   };

   const fetchComune = async () => {
      try {
         const response = await axiosInstance.post(
            `/comuni?id_provincia=${state.city.id}`
         );
         let citiesData = response.data.data.comune_list;
         citiesData = citiesData.map((item) => ({
            ...item,
            id: item.id_comune,
            label: item.comune,
         }));

         citiesData.unshift({
            id_comune: 0,
            id: 0,
            label: "Tutte",
            comune: "Tutte",
         });

         dispatch({ type: GET_CITIES_SUCCESS, payload: citiesData });
      } catch (error) {
         console.log(error, "error fetch comune");
      }
   };

   const fetchProvince = async () => {
      try {
         const response = await axiosInstance.post(
            `/province?id_provincia=${state.province.id}`
         );
         let provinceData = response.data.data.province_list;
         provinceData = provinceData.map((item) => ({
            ...item,
            id: item.id_provincia,
            label: item.provincia,
         }));
         provinceData.unshift({
            id_provincia: 0,
            provincia: "Tutte",
            id: 0,
            label: "Tutte",
         });

         dispatch({ type: GET_PROVINCES_SUCCESS, payload: provinceData });
      } catch (error) {
         console.log(error, "error fetch province");
      }
   };

   const fetchZones = async () => {
      try {
         const response = await axiosInstance.post(
            `/frazioni?id_comune=${state.city.id}`
         );
         let zoneData = response.data.data.frazioni_list.filter(
            (item) => item.frazione !== ""
         );
         zoneData = zoneData.map((item) => ({
            ...item,
            id: item.id_frazione,
            label: item.frazione,
         }));
         if (zoneData.length) {
            zoneData.unshift({
               id_frazione: 0,
               frazione: "Tutte",
               id: 0,
               label: "Tutte",
            });
         } else {
            setZone({
               id_frazione: 0,
               id: 0,
               label: "Tutte",
            });
         }

         dispatch({ type: GET_ZONES_SUCCESS, payload: zoneData });
      } catch (error) {
         console.log(error, "error fetch zones");
      }
   };

   const fetchFilteredData = async (
      pageParam = state.currentPage,
      residentialeParam = state.residentialeListValue.id,
      order_by_param = ""
   ) => {
      dispatch({ type: GET_ADDS_BEGIN });

      try {
         const params = {
            page: pageParam,
            id_comune: state.city.label === "Tutte" ? 0 : state.city.id,
            id_provincia:
               state.province.label === "Tutte" ? 0 : state.province.id,
            id_tipologia:
               state.typology.label === "Tutte" ? 0 : state.typology.id,
            tipo_contratto:
               state.contract.label === "Tutti" ? 0 : state.contract.id,
            id_frazione: state.zone.label === "Tutte" ? 0 : state.zone.id,
            numero_locali:
               state.numberRooms === "Tutti" ? 0 : state.numberRooms.id,
            riferimento: state.rif,
            mq_min:
               state.mqMinListCurrent.label === "0"
                  ? 0
                  : state.mqMinListCurrent.id,
            mq_max:
               state.mqMaxListCurrent.label === "0"
                  ? 0
                  : state.mqMaxListCurrent.id,
            prezzo_min:
               state.priceMin.label === "Tutti" ? 0 : state.priceMin.id,
            prezzo_max:
               state.priceMax.label === "Tutti" ? 0 : state.priceMax.id,
            residenziale:
               residentialeParam === 1 || residentialeParam === 0
                  ? residentialeParam
                  : "",
            order_by:
               order_by_param === "" ? state.order_by.id : order_by_param,
         };
         const urlParams = [];
         Object.entries(params).forEach(([key, value]) => {
            urlParams.push(`${key}=${value}`);
         });
         const url = "/immobili?" + urlParams.join("&");

         const response = await axiosInstance.post(url, {
            credentials: "same-origin",
         });
         const data = response.data.data;
         // console.log(data);
         dispatch({ type: GET_ADDS_SUCCESS, payload: data });
      } catch (error) {
         console.log(error, "error fetch in filter");
         dispatch({ type: GET_ADDS_ERROR });
      }
   };

   useEffect(() => {
      Promise.all([
         fetchTypology(),
         fetchProvince(),
         fetchComune(),
         fetchZones(),
      ]).then(() => {
         fetchFilteredData(1, 2);
      });
   }, []);

   useEffect(() => {
      if (isMounted.current) {
         fetchFilteredData();
      }
   }, [
      state.mqMinListCurrent,
      state.mqMaxListCurrent,
      state.priceMin,
      state.priceMax,
   ]);

   useEffect(() => {
      if (state.changePage) {
         fetchFilteredData();
      }
   }, [state.changePage]);

   useEffect(() => {
      if (isMounted.current) {
         fetchFilteredData(1);
      } else {
         isMounted.current = true;
      }
   }, [
      state.contract,
      state.typology,
      state.zone,
      state.numberRooms,
      state.rif,
      state.order_by,
   ]);

   useEffect(() => {
      if (isMounted2.current) {
         fetchZones();
      } else {
         isMounted2.current = true;
      }
   }, [state.city]);

   return (
      <SiteContext.Provider
         value={{
            ...state,
            setContract,
            setTypology,
            setCity,
            setZone,
            setRooms,
            setMqMin,
            setMqMax,
            setPriceMin,
            setPriceMax,
            setRif,
            setPage,
            setOrder,
            resetFilters,
         }}
      >
         {children}
      </SiteContext.Provider>
   );
};
// make sure use
export const useSiteContext = () => {
   return useContext(SiteContext);
};
