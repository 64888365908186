import Splide from "@splidejs/splide";

export default function splideCarousels() {
   const singleAddCarousel = document.querySelector("#addPhotosCarousel");
   if (singleAddCarousel) {
      new Splide(singleAddCarousel, {
         type: "loop",
         pagination: false,
         autoplay: false,
      }).mount();
   }

   const planimetryCarousel = document.querySelector("#planimetryCarousel");
   if (planimetryCarousel) {
      new Splide(planimetryCarousel, {
         type: "loop",
         pagination: false,
         autoplay: false,
      }).mount();
   }
}
