export default function advCarousel() {
   const items = document.querySelectorAll(".advantages__item");
   const items2 = document.querySelectorAll(".buy__item");

   addActiveClass(items);
   addActiveClass(items2);
}

function addActiveClass(items) {
   if (items.length) {
      let i = 0;
      const lng = items.length - 1;

      setInterval(() => {
         removeActiveClass(items);
         items[i].classList.add("active");
         i >= lng ? (i = 0) : i++;
      }, 2600);
   }
}

function removeActiveClass(items) {
   for (let index = 0; index < items.length; index++) {
      const element = items[index];
      element.classList.remove("active");
   }
}
