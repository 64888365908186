import { useState, useRef } from "react";
import { useSiteContext } from "../../context/siteContext";
import { useOnClickOutside } from "../../hooks/clickOutside";

function AddsListHeader({ pageName }) {
   const { defaultTitle, contract, order_by, orders_by, setOrder, filtered } =
      useSiteContext();
   const [openList, setOpenList] = useState(false);
   const ref = useRef();

   useOnClickOutside(ref, () => {
      setOpenList(false);
   });

   function mainTitle() {
      if (pageName) {
         if (pageName === "vendite" || pageName === "affitti") {
            if (defaultTitle === "Alcuni dei nostri immobili") {
               if (contract.label === "Vendita") {
                  return "Immobili in vendita ";
               } else if (contract.label === "Affitto") {
                  return "Immobili in affitto";
               }
            }
         }
      }
      return defaultTitle;
   }

   return (
      <div className="adds__header">
         <div className="adds__titles">
            <h1 className="adds__subtitle section-subtitle">
               Cosa ti offre CamogliCase
            </h1>
            <div className="adds__title section-title">{mainTitle()}</div>
         </div>

         <div
            className={`adds__filter ${
               !filtered || filtered.length < 2 ? "disabled" : ""
            }`}
            ref={ref}
         >
            <div
               onClick={() => setOpenList(!openList)}
               className={`adds__filter-selected ${openList ? "active" : ""}`}
            >
               <div className="adds__filter-label">
                  Ordina per: {order_by.label}
               </div>
               <div
                  className={`adds__filter-arrow ${openList ? "active" : ""}`}
               >
                  <svg
                     width="18"
                     height="10"
                     viewBox="0 0 18 10"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        d="M17.3875 1.26233C17.2713 1.14517 17.1331 1.05217 16.9807 0.988711C16.8284 0.925251 16.665 0.892578 16.5 0.892578C16.335 0.892578 16.1716 0.925251 16.0193 0.988711C15.867 1.05217 15.7287 1.14517 15.6125 1.26233L9.88751 6.98733C9.77131 7.10449 9.63306 7.19748 9.48073 7.26094C9.32841 7.3244 9.16503 7.35707 9.00001 7.35707C8.835 7.35707 8.67162 7.3244 8.51929 7.26094C8.36697 7.19748 8.22872 7.10449 8.11251 6.98733L2.38751 1.26233C2.27131 1.14517 2.13306 1.05217 1.98074 0.988711C1.82841 0.925251 1.66503 0.892578 1.50001 0.892578C1.335 0.892578 1.17162 0.925251 1.01929 0.988711C0.866969 1.05217 0.728718 1.14517 0.612514 1.26233C0.3797 1.49653 0.249023 1.81334 0.249023 2.14358C0.249023 2.47381 0.3797 2.79062 0.612514 3.02483L6.35001 8.76233C7.05314 9.46458 8.00626 9.85903 9.00001 9.85903C9.99377 9.85903 10.9469 9.46458 11.65 8.76233L17.3875 3.02483C17.6203 2.79062 17.751 2.47381 17.751 2.14358C17.751 1.81334 17.6203 1.49653 17.3875 1.26233Z"
                        fill="#373737"
                     />
                  </svg>
               </div>
            </div>

            <div className={`adds__filter-list ${openList ? "active" : ""}`}>
               {orders_by.map((item) => (
                  <div
                     className="adds__filter-item"
                     key={item.id}
                     onClick={() => {
                        setOrder(item);
                        setOpenList(false);
                     }}
                  >
                     {item.label}
                  </div>
               ))}
            </div>
         </div>
      </div>
   );
}

export default AddsListHeader;
