import { useState, useRef, useEffect } from "react";
import { useSiteContext } from "../context/siteContext";
import { useOnClickOutside } from "../hooks/clickOutside";

function Select({
   label,
   tabIndex = "-1",
   optionKey = "contract",
   optionsKey = "contracts",
   fn,
   darkMode,
   svg,
}) {
   const context = useSiteContext();
   const option = context[optionKey];
   const options =
      optionKey === "zone" && context.city.label === "Tutte"
         ? []
         : context[optionsKey];

   const [openOptionsList, setOptionsList] = useState(false);
   const [activeItem, setActiveItem] = useState({ id: 123454 });
   const [activeIndex, setActiveIndex] = useState(12354);

   useEffect(() => {
      if (option.id) {
         setActiveItem(option);
         setActiveIndex(options.findIndex((item) => item.id === option.id));
      }
   }, [option, options]);

   const ref = useRef();
   useOnClickOutside(ref, () => {
      if (openOptionsList) {
         setOptionsList(false);
         if (option.id) {
            setActiveItem(option);
            setActiveIndex(options.findIndex((item) => item.id === option.id));
         }
      }
   });

   function handleKeyDown(e) {
      const code = e.code;
      if (openOptionsList) {
         e.preventDefault();

         if (code === "ArrowDown") {
            const nextIndex =
               activeIndex >= options.length - 1 ? 0 : activeIndex + 1;

            setActiveIndex(nextIndex);
            setActiveItem(options[nextIndex]);
         } else if (code === "ArrowUp") {
            const nextIndex =
               activeIndex <= 0 ? options.length - 1 : activeIndex - 1;
            setActiveIndex(nextIndex);
            setActiveItem(options[nextIndex]);
         } else if (code === "Tab") {
            setOptionsList(false);
            setActiveItem(option);
            setActiveIndex(options.findIndex((item) => item.id === option.id));
         } else if (code === "Enter") {
            fn(activeItem);
            setOptionsList(false);
         }
      } else {
         if (code === "ArrowDown" || code === "ArrowUp") {
            setOptionsList(true);
         }
      }
   }
   function handleItemClick(id) {
      const clickedItem = options.find((item) => item.id === id);
      fn(clickedItem);
      setOptionsList(false);
   }

   return (
      <div
         className={`c-select ${darkMode ? "c-select--dark" : ""}`}
         ref={ref}
         onKeyDown={handleKeyDown}
      >
         <div className="c-select__label">{label}</div>
         <div
            className="c-select__selected"
            tabindex={tabIndex}
            onClick={() => setOptionsList(!openOptionsList)}
         >
            {svg ? svg : null}
            <span>
               {option.label === ">200 mq" || option.label === "+500.000 €"
                  ? "Tutti"
                  : option.label}
            </span>

            <svg
               width="30"
               height="30"
               viewBox="0 0 30 30"
               fill="none"
               className={`c-select__arrow ${
                  openOptionsList && options.length > 0 ? "active" : ""
               }`}
            >
               <path
                  d="M23.3875 10.2623C23.2713 10.1452 23.1331 10.0522 22.9807 9.98871C22.8284 9.92525 22.665 9.89258 22.5 9.89258C22.335 9.89258 22.1716 9.92525 22.0193 9.98871C21.867 10.0522 21.7287 10.1452 21.6125 10.2623L15.8875 15.9873C15.7713 16.1045 15.6331 16.1975 15.4807 16.2609C15.3284 16.3244 15.165 16.3571 15 16.3571C14.835 16.3571 14.6716 16.3244 14.5193 16.2609C14.367 16.1975 14.2287 16.1045 14.1125 15.9873L8.38751 10.2623C8.27131 10.1452 8.13306 10.0522 7.98074 9.98871C7.82841 9.92525 7.66503 9.89258 7.50001 9.89258C7.335 9.89258 7.17162 9.92525 7.01929 9.98871C6.86697 10.0522 6.72872 10.1452 6.61251 10.2623C6.3797 10.4965 6.24902 10.8133 6.24902 11.1436C6.24902 11.4738 6.3797 11.7906 6.61251 12.0248L12.35 17.7623C13.0531 18.4646 14.0063 18.859 15 18.859C15.9938 18.859 16.9469 18.4646 17.65 17.7623L23.3875 12.0248C23.6203 11.7906 23.751 11.4738 23.751 11.1436C23.751 10.8133 23.6203 10.4965 23.3875 10.2623Z"
                  fill="#373737"
               />
            </svg>
         </div>

         {options.length ? (
            <div
               className={`c-select__list ${openOptionsList ? "active" : ""}`}
            >
               {options.map((item) => (
                  <div
                     className={`c-select__list-item ${
                        activeItem.id === item.id ? "active" : ""
                     }`}
                     key="item.id"
                     onClick={() => handleItemClick(item.id)}
                  >
                     {item.label}
                  </div>
               ))}
            </div>
         ) : null}
      </div>
   );
}
export default Select;
