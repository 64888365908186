export const GET_ADDS_BEGIN = "GET_ADDS_BEGIN";
export const GET_ADDS_SUCCESS = "GET_ADDS_SUCCESS";
export const GET_ADDS_ERROR = "GET_ADDS_ERROR";

export const SET_ZONE = "SET_ZONE";
export const SET_CITY = "SET_CITY";
export const SET_ROOMS = "SET_ROOMS";
export const SET_CONTRACT = "SET_CONTRACT";
export const SET_TYPOLOGY = "SET_TYPOLOGY";
export const SET_MQ_MIN = "SET_MQ_MIN";
export const SET_MQ_MAX = "SET_MQ_MAX";
export const SET_PRICE_MIN = "SET_PRICE_MIN";
export const SET_PRICE_MAX = "SET_PRICE_MAX";
export const SET_RIF = "SET_RIF";

export const RESET_FILTERS = "RESET_FILTERS";

export const SET_ORDER = "SET_ORDER";

export const SET_PAGE = "SET_PAGE";

export const GET_TYPOLOGY_SUCCESS = "GET_TYPOLOGY_SUCCESS";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_PROVINCES_SUCCESS = "GET_PROVINCES_SUCCESS";
export const GET_ZONES_SUCCESS = "GET_ZONES_SUCCESS";
