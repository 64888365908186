import {
   GET_ADDS_BEGIN,
   GET_ADDS_ERROR,
   GET_ADDS_SUCCESS,
   GET_CITIES_SUCCESS,
   GET_PROVINCES_SUCCESS,
   GET_TYPOLOGY_SUCCESS,
   GET_ZONES_SUCCESS,
   RESET_FILTERS,
   SET_CITY,
   SET_CONTRACT,
   SET_MQ_MAX,
   SET_MQ_MIN,
   SET_ORDER,
   SET_PAGE,
   SET_PRICE_MAX,
   SET_PRICE_MIN,
   SET_RIF,
   SET_ROOMS,
   SET_TYPOLOGY,
   SET_ZONE,
} from "./siteActions";

const stateIsDefaults = (state) => {
   if (
      // state.currentPage === 1 &&
      state.typology.label === "Tutte" &&
      state.city.label === "Tutte" &&
      state.rif === "" &&
      // state.contract.label === "Tutti" &&
      state.zone.label === "Tutte" &&
      (state.mqMinListCurrent.label === "0" ||
         state.mqMinListCurrent.label === "") &&
      (state.mqMaxListCurrent.label === ">200 mq" ||
         state.mqMaxListCurrent.label === "") &&
      state.priceMin.label === "0" &&
      state.priceMax.label === "+500.000 €"
   )
      return true;
   return false;
};

const products_reducer = (state, action) => {
   if (action.type === RESET_FILTERS) {
      return {
         ...state,
         currentPage: 1,
         typology: {
            id: 12345678,
            label: "Tutte",
         },
         city: {
            id: 0,
            label: "Tutte",
         },
         contract:
            pageVariables.pageName === "affitti"
               ? {
                    id: 2,
                    label: "Affitto",
                 }
               : {
                    id: 1,
                    label: "Vendita",
                 },
         numberRooms: {
            id: 0,
            label: "Tutti",
         },
         rif: "",
         zone: {
            id: 12345678,
            label: "Tutte",
         },
         mqMinListCurrent: {
            id: 0,
            label: "0",
         },
         mqMaxListCurrent: {
            id: 0,
            label: ">200 mq",
         },
         priceMin: {
            id: 0,
            label: "0",
         },
         priceMax: {
            id: 0,
            label: "+500.000 €",
         },
      };
   }
   if (action.type === SET_ORDER) {
      return { ...state, order_by: action.payload, currentPage: 1 };
   }
   if (action.type === SET_PAGE) {
      return {
         ...state,
         currentPage: +action.payload,
         changePage: action.payload,
      };
   }
   if (action.type === SET_CITY) {
      return {
         ...state,
         city: action.payload,
         zone: {
            id: 12345678,
            label: "Tutte",
         },
         currentPage: 1,
      };
   }
   if (action.type === SET_ZONE) {
      return { ...state, zone: action.payload, currentPage: 1 };
   }
   if (action.type === SET_CONTRACT) {
      return { ...state, contract: action.payload, currentPage: 1 };
   }
   if (action.type === SET_ROOMS) {
      return { ...state, numberRooms: action.payload, currentPage: 1 };
   }
   if (action.type === SET_TYPOLOGY) {
      return { ...state, typology: action.payload, currentPage: 1 };
   }
   if (action.type === SET_RIF) {
      let value = action.payload;
      value =
         value.toLowerCase() === "tutti" || value.toLowerCase() === "tutte"
            ? ""
            : value;
      return { ...state, rif: value, currentPage: 1 };
   }
   if (action.type === SET_MQ_MIN) {
      let value = action.payload;

      if (
         value.id !== 0 &&
         state.mqMaxListCurrent.id !== 0 &&
         value.id >= state.mqMaxListCurrent.id
      ) {
         value = state.mqMinList[0];

         return { ...state, mqMinListCurrent: value };
      } else {
         return { ...state, mqMinListCurrent: value, currentPage: 1 };
      }
   }
   if (action.type === SET_MQ_MAX) {
      let value = action.payload;
      if (
         state.mqMinListCurrent.id !== 0 &&
         value.id !== 0 &&
         state.mqMinListCurrent.id >= value.id
      ) {
         value = state.mqMaxList[state.mqMaxList.length - 1];
         return { ...state, mqMaxListCurrent: value };
      } else {
         return { ...state, mqMaxListCurrent: value, currentPage: 1 };
      }
   }
   if (action.type === SET_PRICE_MIN) {
      let value = action.payload;

      if (
         value.id !== 0 &&
         state.priceMax.id !== 0 &&
         value.id >= state.priceMax.id
      ) {
         value = state.pricesMin[0];
         return { ...state, priceMin: value };
      } else {
         return { ...state, priceMin: value, currentPage: 1 };
      }
   }
   if (action.type === SET_PRICE_MAX) {
      let value = action.payload;
      if (
         state.priceMin.id !== 0 &&
         value.id !== 0 &&
         state.priceMin.id >= value.id
      ) {
         value = state.pricesMax[state.pricesMax.length - 1];
         return { ...state, priceMax: value };
      } else {
         return { ...state, priceMax: value, currentPage: 1 };
      }
   }
   if (action.type === GET_TYPOLOGY_SUCCESS) {
      return { ...state, typologies: action.payload };
   }
   if (action.type === GET_CITIES_SUCCESS) {
      return { ...state, cities: action.payload };
   }
   if (action.type === GET_PROVINCES_SUCCESS) {
      return { ...state, provinces: action.payload };
   }
   if (action.type === GET_ZONES_SUCCESS) {
      return { ...state, zones: action.payload };
   }
   if (action.type === GET_ADDS_BEGIN) {
      return { ...state, loading: true, filtered: [] };
   }
   if (action.type === GET_ADDS_SUCCESS) {
      let title = "";
      let showClose = false;
      if (stateIsDefaults(state)) {
         title = "Alcuni dei nostri immobili";
         showClose = false;
      } else {
         title = "Risultati della ricerca";
         showClose = true;
      }
      return {
         ...state,
         loading: false,
         error: false,
         filtered: action.payload.immobili_list,
         currentPage: action.payload.current_page,
         pages: action.payload.pages,
         defaultTitle: title,
         showClearFilters: showClose,
         defaultCount: action.payload.items,
      };
   }
   if (action.type === GET_ADDS_ERROR) {
      return { ...state, loading: false, error: "Si è verificato un errore." };
   }

   throw new Error(`No Matching "${action.type}" - action type`);
};

export default products_reducer;
