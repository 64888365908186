import React from "react";
import AddsContainer from "./AddsList/AddsContainer";

import HomeIntro from "./HomeIntro";

function MainComponent({ pageVars }) {
   const {
      homeTitle,
      homeSubtitle,
      homeImg,
      homeMobileImg,
      isFrontPage,
      pageName,
   } = pageVars;

   return (
      <>
         <HomeIntro
            title={homeTitle}
            subtitle={homeSubtitle}
            homeImg={homeImg}
            homeMobileImg={homeMobileImg}
            isFrontPage={isFrontPage}
         ></HomeIntro>
         <AddsContainer isFrontPage={isFrontPage} pageName={pageName} />
      </>
   );
}

export default MainComponent;
