function Info({ title, cclass = "" }) {
   return (
      <div
         className={`info ${cclass}`}
         dangerouslySetInnerHTML={{ __html: title }}
      ></div>
   );
}

export default Info;
