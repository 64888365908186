import React from "react";
import { useSiteContext } from "../../context/siteContext";

const AddsPagination = React.forwardRef((_, ref) => {
   const { currentPage, loading, pages, defaultCount, setPage } =
      useSiteContext();

   if (!pages || pages <= 1 || loading) return null;

   function clickHandler(newPage) {
      setPage(newPage);
      ref.current?.scrollIntoView({ behavior: "smooth" });
   }

   return (
      <div className="adds__pagination pagination">
         <div
            onClick={() => clickHandler(1)}
            className="pagination__item pagination__item--arrow"
         >
            <svg
               width="31"
               height="16"
               viewBox="0 0 31 16"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <path
                  d="M0.292891 8.70711C-0.0976334 8.31658 -0.0976334 7.68342 0.292891 7.29289L6.65685 0.928932C7.04738 0.538408 7.68054 0.538408 8.07107 0.928932C8.46159 1.31946 8.46159 1.95262 8.07107 2.34315L2.41421 8L8.07107 13.6569C8.46159 14.0474 8.46159 14.6805 8.07107 15.0711C7.68054 15.4616 7.04738 15.4616 6.65685 15.0711L0.292891 8.70711ZM31 9H0.999998V7H31V9Z"
                  fill="#626262"
               />
            </svg>
         </div>

         {[...Array(pages)].map((_, index) => (
            <div
               className={`pagination__item ${
                  index + 1 === +currentPage ? "active" : ""
               }`}
               key={index}
               onClick={() => clickHandler(index + 1)}
            >
               {index + 1}
            </div>
         ))}

         <div
            onClick={() => clickHandler(pages)}
            className="pagination__item pagination__item--arrow"
         >
            <svg
               width="31"
               height="16"
               viewBox="0 0 31 16"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <path
                  d="M30.7071 8.70711C31.0976 8.31658 31.0976 7.68342 30.7071 7.29289L24.3431 0.928932C23.9526 0.538408 23.3195 0.538408 22.9289 0.928932C22.5384 1.31946 22.5384 1.95262 22.9289 2.34315L28.5858 8L22.9289 13.6569C22.5384 14.0474 22.5384 14.6805 22.9289 15.0711C23.3195 15.4616 23.9526 15.4616 24.3431 15.0711L30.7071 8.70711ZM0 9H30V7H0V9Z"
                  fill="#626262"
               />
            </svg>
         </div>

         <div className="pagination__pages">Totale: {defaultCount}</div>
      </div>
   );
});

export default AddsPagination;
