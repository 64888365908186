import "./styles/main.scss";

import MainComponent from "./scripts/react/components/MainComponent";
import { SiteContextProvider } from "./scripts/react/context/siteContext";

// import touchMenu from "./scripts/modules/touch-menu";

// import "./scripts/modules/libs/popup";
// import "./scripts/modules/sliders";

// import "./scripts/modules/tippy";

// import * as flsFunctions from "./scripts/modules/functions.js";

import "./scripts/modules/gallery";

import menu from "./scripts/modules/menu";
import advCarousel from "./scripts/modules/advCarousel";
import splideCarousels from "./scripts/modules/splideCarousels";
import singleEstateMap from "./scripts/modules/singleEstateMap";
import SingleSimilarComponent from "./scripts/react/components/SingleSimilarComponent";
import headerFixed from "./scripts/modules/headerFixed";
import showTop from "./scripts/modules/showTop";
// import * as flsScroll from "./scripts/modules/scroll/scroll.js";
// import "./scripts/modules/libs/watcher.js";

document.addEventListener("DOMContentLoaded", function () {
   window["FLS"] = false;

   if (window.document.documentMode) {
      // alert('Internet Explorer');
      console.log("internet expolorer");
   } else {
      // gsapAnimation();
      // touchMenu();
   }

   menu();
   advCarousel();
   splideCarousels();
   singleEstateMap();

   headerFixed();
   showTop();

   // Функции работы скроллом

   // Плавная навигация по странице
   // flsScroll.pageNavigation();

   // Функционал добавления классов к хедеру при прокрутке
   // flsScroll.headerScroll();

   // Функционал липкого блока
   // flsScroll.stickyBlock();

   //
   const rootElement = document.querySelector("#root");
   if (rootElement) {
      ReactDOM.render(
         <SiteContextProvider>
            <MainComponent pageVars={pageVariables} />
         </SiteContextProvider>,
         rootElement
      );
   }

   const singleSimilarAdds = document.querySelector("#similarAdds");
   if (singleSimilarAdds) {
      ReactDOM.render(
         <SingleSimilarComponent pageId={pageId} />,
         singleSimilarAdds
      );
   }
});
