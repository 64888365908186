import { useRef } from "react";
import AddsListHeader from "./AddsListHeader";
import AddsList from "./AddsList";
import AddsPagination from "./AddsPagination";

function AddsListContainer({ isFrontPage, pageName }) {
   const ref = useRef();

   return (
      <div className={`adds ${isFrontPage ? "" : "adds--inner"}`}>
         <div className="container">
            <AddsListHeader pageName={pageName} />
            <div ref={ref} className="adds__list">
               <AddsList />
            </div>
            <AddsPagination ref={ref} />
         </div>
      </div>
   );
}

export default AddsListContainer;
