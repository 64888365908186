import { useEffect, useState } from "react";
import { axiosInstance } from "../utils/axiosInstances";
import AddsItem from "./AddsList/AddsItem";

function SingleSimilarComponent({ pageId }) {
   const [similarAdds, setSimilarAdds] = useState([]);

   useEffect(() => {
      async function fetchSimilar() {
         try {
            const url = "/immobili?page=1";

            const response = await axiosInstance.post(url, {
               credentials: "same-origin",
            });
            const data = response.data.data.immobili_list;
            const shuffled = data
               .sort(() => 0.5 - Math.random())
               .filter((item) => item.id != +pageId);

            let selected = shuffled.slice(0, 2);
            setSimilarAdds(selected);
         } catch (error) {
            console.log(error, "error fetch similar adds");
         }
      }

      fetchSimilar();
   }, []);

   return (
      <div className="similar-adds">
         {similarAdds.map((item) => (
            <AddsItem key={item.id} item={item} specialClass="similar-item" />
         ))}
      </div>
   );
}

export default SingleSimilarComponent;
