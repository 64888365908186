export default function showTop() {
   const btn = document.querySelector(".go-top");

   if (btn) {
      window.addEventListener("scroll", function () {
         if (window.pageYOffset > 350) {
            btn.classList.add("active");
         } else {
            btn.classList.remove("active");
         }
      });

      btn.addEventListener("click", function () {
         window.scrollTo({
            top: 0,
            behavior: "smooth",
         });
      });
   }
}
