import React from "react";
import Info from "../ Info";
import { useSiteContext } from "../../context/siteContext";
import Loading from "../Loading";
import AddsItem from "./AddsItem";

function AddsList() {
   const { loading, error, filtered } = useSiteContext();

   if (loading) return <Loading />;

   if (error)
      return (
         <Info
            title="Errore del server.<br> Prova ad effettuare una nuova ricerca"
            cclass="adds__error"
         />
      );

   if (!filtered || filtered.length === 0)
      return (
         <Info
            title="Nessun immobile corrisponde ai criteri di ricerca impostati.<br>Prova ad effettuare una nuova ricerca"
            cclass="adds__info"
         />
      );

   return (
      <>
         {filtered.map((item) => (
            <AddsItem key={item.id} item={item} />
         ))}
      </>
   );
}

export default AddsList;
