export const initialState = {
   defaultTitle: "Alcuni dei nostri immobili",
   defaultCount: "",
   loading: false,
   error: false,
   showClearFilters: false,
   currentPage: 1,
   changePage: false,
   pages: 1,
   rif: "",
   filtered: [],
   orders_by: [
      {
         id: "date_desc",
         label: "Più recenti",
      },
      {
         id: "price_desc",
         label: "Più costosi",
      },
      {
         id: "area_desc",
         label: "Più grandi",
      },
      {
         id: "price_asc",
         label: "Meno costosi",
      },
      {
         id: "area_asc",
         label: "Meno grandi",
      },
      {
         id: "date_asc",
         label: "Meno recenti",
      },
   ],
   order_by: {
      id: "date_desc",
      label: "Più recenti",
   },
   residentialeListValue: {
      id: 2,
      label: "Tutti",
   },
   contracts: [
      {
         id: 3,
         label: "Tutti",
      },
      {
         id: 1,
         label: "Vendita",
      },
      {
         id: 2,
         label: "Affitto",
      },
   ],
   contract:
      pageVariables.pageName === "affitti"
         ? { id: 2, label: "Affitto" }
         : {
              id: 1,
              label: "Vendita",
           },
   typologies: [
      {
         id: 1,
         title: "Tutte",
      },
   ],
   typology: {
      id: 12345678,
      label: "Tutte",
   },
   provinces: [],
   province: {
      id: 1,
      label: "Tutte",
   },
   cities: [],
   city: {
      id: 0,
      label: "Tutte",
   },
   zones: [],
   zone: {
      id: 12345678,
      label: "Tutte",
   },
   numberRooms: {
      id: 0,
      label: "Tutti",
   },
   numbersRooms: [
      {
         id: 0,
         label: "Tutti",
      },
      {
         id: 1,
         label: "1",
      },
      {
         id: 2,
         label: "2",
      },
      {
         id: 3,
         label: "3",
      },
      {
         id: 4,
         label: "4",
      },
      {
         id: 5,
         label: "5",
      },
      {
         id: 6,
         label: "6",
      },
   ],
   pricesMin: [
      {
         id: 0,
         label: "0",
      },
      {
         id: 10000,
         label: "10.000 €",
      },
      {
         id: 50000,
         label: "50.000 €",
      },

      {
         id: 100000,
         label: "100.000 €",
      },
      {
         id: 150000,
         label: "150.000 €",
      },
      {
         id: 200000,
         label: "200.000 €",
      },
      {
         id: 250000,
         label: "250.000 €",
      },

      {
         id: 500000,
         label: "500.000 €",
      },
   ],
   priceMin: {
      id: 0,
      label: "0",
   },
   pricesMax: [
      {
         id: 10000,
         label: "10.000 €",
      },
      {
         id: 50000,
         label: "50.000 €",
      },

      {
         id: 100000,
         label: "100.000 €",
      },
      {
         id: 150000,
         label: "150.000 €",
      },
      {
         id: 200000,
         label: "200.000 €",
      },
      {
         id: 250000,
         label: "250.000 €",
      },

      {
         id: 0,
         label: "+500.000 €",
      },
   ],
   priceMax: {
      id: 0,
      label: "+500.000 €",
   },
   mqMinList: [
      {
         id: 0,
         label: "0",
      },
      {
         id: 20,
         label: "20 mq",
      },
      {
         id: 50,
         label: "50 mq",
      },
      {
         id: 100,
         label: "100 mq",
      },
      {
         id: 150,
         label: "150 mq",
      },
   ],
   mqMinListCurrent: {
      id: 0,
      label: "0",
   },

   mqMaxList: [
      {
         id: 20,
         label: "20 mq",
      },
      {
         id: 50,
         label: "50 mq",
      },
      {
         id: 100,
         label: "100 mq",
      },
      {
         id: 150,
         label: "150 mq",
      },
      {
         id: 0,
         label: ">200 mq",
      },
   ],
   mqMaxListCurrent: {
      id: 0,
      label: ">200 mq",
   },
};
